import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import IntegrationPartnerPage from '@src/templates/integration-partner/integration-partner';
import { useCallback, useEffect, useState } from 'react';
import { PAGE_CONFIGURATION_MOCK } from '@shared/contentstack/live-preview/mock-data/page-configuration-mock';

const PartnerCardPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'partner_card',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;

    const result = response[0][0];

    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    !!livePreviewData && (
      <IntegrationPartnerPage
        pageContext={{
          page_configuration: PAGE_CONFIGURATION_MOCK,
          header_style: { header_style: '' },
          featured_partners: [livePreviewData],
          components: [],
          featured_section_title: '',
        }}
      />
    )
  );
};

export default PartnerCardPreviewPage;
