export const PAGE_CONFIGURATION_MOCK = {
  seo_metadata: {
    no_index: true,
    no_follow: true,
    meta_title: '',
    meta_keywords: '',
    meta_description: '',
    canonical_url: '',
  },
  social_media_metadata: {
    title: '',
    image: { url: '' },
    description: '',
  },
};
